<template>
  <h1>Announcement Management</h1>
  <div class="row">
    <div class="col-sm-3">
      <MDBInput v-model="filterParam" class="mb-4" placeholder="Filter"/>
    </div>
    <div class="col-sm-3 offset-6">
      <button class="button btn-primary " @click="routeToCreate">
        Create Announcement
      </button>
    </div>
  </div>
  <MDBDatatable :dataset="announcements" :search="filterParam" striped @render="setActions"
                :maxHeight="635" fixed-header hover/>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import axios from "axios";
import {MDBDatatable, MDBInput} from "mdb-vue-ui-kit";
import {allAnnouncements, deleteAnnouncement} from "@/Helpers/ApiCalls/AnnouncementAPICalls";
import store from "@/store";
import router from "@/router";
import Swal from "sweetalert2";

export default {
  name: "AnnouncementDashboard",
  components: {MDBDatatable, MDBInput},
  setup() {
    const announcements = ref({
      columns: [
        {label: "Title", field: "title"},
        {label: "Date Created", field: "dateCreated"},
        {label: "Start Date", field: "startDate"},
        {label: "Expiration Date", field: "expirationDate"},
        {label: "Created By", field: "createdBy"},
        {label: "Last Update By", field: "lastUpdateBy"},
        {label: "Actions", field: "actions"},
      ],
      rows: []
    });
    const filterParam = ref("");
    const authToken = store.getters['auth/authenticated'];

    onBeforeMount(() => {
      getAnnouncements();
    })

    async function getAnnouncements() {
      await axios.get(allAnnouncements(), {
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then((result) => {
        announcements.value.rows = result.data.map((a) => ({
          title: `${a.announcementTitle}`,
          dateCreated: new Date(a.dateCreated).toLocaleDateString(),
          startDate: new Date(a.activeStartDate).toLocaleDateString(),
          expirationDate: new Date(a.expirationDate).toLocaleDateString(),
          createdBy: a.createdByUsername,
          lastUpdateBy: a.lastUpdateByUserName,
          actions: `<a class="bi-pencil-square" href="/admin/announcement/edit/${a.uuid}"></a>&#9;<button class="del-btn bi-trash" data-mdb-id="${a.uuid}"></button>`
        }));
      })
    }

    const setActions = () => {
      let del_buttons = document.getElementsByClassName('del-btn');
      Array.prototype.forEach.call(del_buttons, function (btn) {
        if (btn.getAttribute("click-listener") !== "true") {
          btn.addEventListener("click", () => {
            deleteHelper(btn.attributes['data-mdb-id']);
          })
        }
        btn.setAttribute("click-listener", "true");
      })

      let edit_buttons = document.getElementsByClassName('edit-btn');
      Array.prototype.forEach.call(edit_buttons, function (btn) {
        if (btn.getAttribute("click-listener") !== "true") {
          btn.addEventListener("click", () => {
            router.push({name: 'EditAnnouncement', params: {announcementUuid: btn.getAttribute['data-mdb-id']}});
          })
        }
        btn.setAttribute("click-listener", "true");
      })
    }

    function deleteHelper(uuid) {
      Swal.fire({
        title: "Are You Sure?",
        text: "Deleting an announcement is irreversible",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then((result) => {
        if (result.isConfirmed) {
          deleteAnnouncementByUuid(uuid.value);
        }
      })
    }

    async function deleteAnnouncementByUuid(uuid) {
      await axios.delete(deleteAnnouncement(uuid), {
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then(() => {
        Swal.fire({
          title: "Deleted",
          icon: "success",
          text: "That announcement was deleted",
        }).then(() => {
          getAnnouncements();
        })
      })
    }

    function routeToCreate() {
      router.push({name: 'CreateAnnouncement'});
    }

    return {
      announcements,
      filterParam,
      setActions,
      routeToCreate
    }
  }
}
</script>

<style scoped>

</style>